.message_table {
    font-family: Consolas;
    font-weight:bold;
    font-size: 12px;
    align: center;
    vertical-align: middle;
    width: 75px;
}
.message_location_table{
    font-family: Consolas;
    font-size: 15px;
    font-weight: 500;
    text-align:left;
    vertical-align: middle;
    text-align: justify;
}

.table {
    text-align:center;
    display: inline-block;
    padding: 7px;
    vertical-align: middle;
}

.location  {
    color: gray;
}

.time {
    vertical-align: middle;
}
.OutForDelivery {
    color: #e77f11;
}
.Delivered {
    color: #27ae60;
}
.InTransit {
    color: #3498db;
}
.FulfillmentProcessed {
    color: #00FF00;
}
.InfoReceived {
    color: #214977;
}
.Exception {
    color: #c0392b;
}
.AttemptFail {
    color: #9B59B6;
}
.Pending {
    color: #BDC3C7;
}
