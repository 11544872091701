.result {
    padding: 30px;
    font-family: Consolas,"courier new", Arial, sans-serif;
}
.button_back_to_index {
    text-align: left;
}
.button_back_to_index_error {
    text-align: center;
}
.button_back_to_index_error {
    cursor: pointer;
    color: #0264AA;
    transition: color 0.4s;
}
.button_back_to_index_error:hover {
    color: #03B6DF;
}
.error_message {
    margin: 30px;
}


.tracking_title {
    display: block;
}

.tracking_outline {
    border: 1px solid gray;
    max-width: 800px;
    box-sizing: border-box;
    text-align: left;
}

.gif {
    max-width: 100px;
    margin: auto;
    text-align: center;
}

.tracking_info_label {
    width: 150px;
}
.icon {
    box-sizing: border-box;
    display: inline-block;
    margin-left: 8px;
    margin-right: 15px;
    vertical-align: middle;
}


i {
    border: solid #0264AA;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
}


i:hover {
    border: solid #03B6DF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    cursor: pointer;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.right {
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
}

.fade-enter-active, .fade-leave-active {
    transition: all .3s;
}

.error-appear {
    opacity: 0.01;
    margin-top: 30px;
}

.error-appear.error-appear-active {
    opacity: 1;
    margin-top: 30px;
    transition: opacity .5s ease-in;
}
