.checkpoints_date{
    background-color: lightgray;
    box-sizing: border-box;
    height: 35px;
    padding: 8px;
}
checkpoints_simplify_date{
    background-color: lightgray;
    box-sizing: border-box;
    height: 35px;
    padding: 8px;
}

.checkpoints_detail{
    margin: 5px;
    padding: 5px;
}

.message {
    padding: 8px;
}

.time {
    display:inline-block;
}

Message {
    margin: 10px;
}

.example-appear {
    opacity: 0.01;
    margin-top: 50px;
}

.example-appear.example-appear-active {
    opacity: 1;
    margin-top: 0px;
    transition: opacity .5s ease-in , margin-top .5s ease-in-out;
}

